import React from 'react'
import NewsPage from '../'
import { graphql } from 'gatsby'

export const ByCategory = ({ data, pageContext }) => {
  return (
    <NewsPage
      data={data}
      category={pageContext.title}
      pageContext={pageContext}
    />
  )
}
export default ByCategory
// re-querying the page is stupid,
// how do I fix this?
// maybe just have the category filter in the original
// news page query?
export const ByCategoryQuery = graphql`
  query byCategoryQuery($title: String!, $limit: Int, $skip: Int) {
    contentfulPage(heading: { eq: "News" }) {
      title
      slug
      heading
      subHeading
      image {
        fluid(
          maxWidth: 1600
          maxHeight: 350
          resizingBehavior: FILL
          cropFocus: BOTTOM
        ) {
          ...GatsbyContentfulFluid
        }
      }
      content {
        content
        json
      }
    }

    allContentfulCategory(
      sort: { fields: [title], order: ASC }
      filter: { post: { elemMatch: { id: { ne: null } } } }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }

    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { category: { title: { eq: $title } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          content {
            json
          }
          category {
            title
            slug
          }
          publishDate
          featuredImage {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
